<template>
     <v-autocomplete
        class="autocomplete-parent"
        v-model="inputVal"
        :id="id"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        validate-on-blur
        outlined
        required
        chips
        deletable-chips
        multiple
        small-chips
        dense
        hide-details="auto"
    ></v-autocomplete>
</template>

<script>
export default {
  name: 'AutoComplete',
  props: {
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    },
    selected: {
      type: Array,
      required: false
    },
    value: {
      type: Array,
      required: false
    }
  },
  data () {
    return {}
  },
  created () {
    if (this.selected) { this.updated(this.selected) }
  },
  methods: {
    updated (value) {
      this.$emit('input', value)
    }
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style>
  .v-chip__content:not(.v-icon) {
    word-wrap: break-word;
    width: 90px;
    max-width: 90px;
    margin-right: 22px;
    padding-right: 25px;
  }
  .v-chip__content > button {
    position: absolute !important;
    right: 4px;
    padding: 13px;
    background-color: #e0e0e0;
  }
  span.v-chip--select {
    margin-top: 1px !important;
    margin-bottom: 2px !important;
  }
  .autocomplete-parent .v-select__selections{
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .theme--light.v-chip:not(.v-chip--active) {
    background: #006446;
    color: white;
  }
  span.v-chip__content>button{
    background-color: #006446;
    color: white !important;
  }
</style>
